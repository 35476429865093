// ページ内アンカーリンクのスクロールエフェクト // Scrolling effect for anchor links within the page
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定) // Set header height to headerHight if header is fixed (PC/SP setting required)
jQuery(function ($) {
	var headerHeight = 0; // ヘッダー追従でない場合 // if not header-following
	// var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合 // if header following
	$('a[href^="#"]').click(function () {
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		if (target.length > 0) {
			var position = target.offset().top - headerHeight;
			$("html, body").animate({ scrollTop: position }, 550, "swing");
			return false;
		}
	});
})
